import { APPLICATION, PAGE } from "#/constants";
import React, { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import { GlobalContext } from "./api/global.context";
import Loader from "./components/Loader";
import MainLayout from "./components/MainLayout";

const LazyLogin = lazy(() => import("#/containers/Login"));
const LazySignUp = lazy(() => import("#/containers/SignUp"));
const LazySignUpConfirmation = lazy(
  () => import("#/containers/SignUpConfirmation")
);
const LazyNotFound = lazy(() => import("#/containers/NotFound"));

const LazyPayMonthly = lazy(() => import("#/containers/Monthly/PayMonthly"));
const LazyMonthlyHistory = lazy(
  () => import("#/containers/Monthly/MonthlyHistory")
);
const LazyRegisterForm = lazy(() => import("#/containers/Register/Form"));
const LazyRegisterPassword = lazy(
  () => import("#/containers/Register/Password")
);
const LazySpontaneousContribution = lazy(
  () => import("#/containers/SpontaneousContribution")
);

const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { state } = useContext(GlobalContext);
  const isLogged = !!state.session;

  return (
    <Route
      {...props}
      render={({ location }) =>
        isLogged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PAGE.LOGIN(),
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const Routes: React.FC = () => {
  return (
    <Router basename={APPLICATION.PUBLIC_URL}>
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <Switch>
            <Route exact={true} path={PAGE.SPONTANEOUS_CONTRIBUTION()}>
              <LazySpontaneousContribution />
            </Route>
            <Route exact={true} path={PAGE.LOGIN()}>
              <LazyLogin />
            </Route>
            <Route exact={true} path={PAGE.SIGNUP()}>
              <LazySignUp />
            </Route>
            <Route exact={true} path={PAGE.SIGNUP_CONFIRMATION()}>
              <LazySignUpConfirmation />
            </Route>
            <PrivateRoute exact={true} path={PAGE.PAY_MONTHLY()}>
              <LazyPayMonthly />
            </PrivateRoute>
            <PrivateRoute exact={true} path={PAGE.MONTHLY_PAYMENT_HISTORY()}>
              <LazyMonthlyHistory />
            </PrivateRoute>
            <PrivateRoute exact={true} path={PAGE.REGISTER_FORM()}>
              <LazyRegisterForm />
            </PrivateRoute>
            <PrivateRoute exact={true} path={PAGE.REGISTER_PASSWORD()}>
              <LazyRegisterPassword />
            </PrivateRoute>

            <PrivateRoute exact={true} path="/">
              <Redirect to={PAGE.MONTHLY_PAYMENT_HISTORY()} />
            </PrivateRoute>

            <Route>
              <LazyNotFound />
            </Route>
          </Switch>
        </MainLayout>
      </Suspense>
    </Router>
  );
};
