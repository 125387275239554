import clsx from "clsx";
import React from "react";
import styles from "./styles.module.scss";

const Loader: React.FC = () => (
  <div className={clsx(styles["loader"])}>
    <div className={clsx(styles["dots"])}>
      <div className={clsx(styles["dot"])}></div>
      <div className={clsx(styles["dot"])}></div>
      <div className={clsx(styles["dot"])}></div>
    </div>
  </div>
);

export default Loader;
