import { GlobalContextProvider } from "#/api/global.context";
import { ErrorBoundary } from "#/components/ErrorBoundary";
import App from "#/containers/App";
import React from "react";
import { render } from "react-dom";
import "./global.scss";

render(
  <ErrorBoundary>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
