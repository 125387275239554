import { GlobalContext, GlobalContextFunctions } from "#/api/global.context";
import { Meta } from "#/components/Meta";
import { SMALL_SCREEN } from "#/constants";
import { Routes } from "#/routes";
import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import React, { useContext, useEffect } from "react";
import { hot } from "react-hot-loader/root";

export const App: React.FC = () => {
  const { state, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    function handleResize() {
      const smallScreen = window.innerWidth < SMALL_SCREEN;
      if (smallScreen !== state.smallScreen) {
        dispatch({
          type: GlobalContextFunctions.setSmallScreen,
          smallScreen,
        });
      }
    }
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch, state.smallScreen]);

  return (
    <ConfigProvider locale={ptBR}>
      <Meta
        titleTemplate="%s - Sociedade Beneficente Espírita Bezerra de Menezes - SBEBM"
        defaultTitle="Sociedade Beneficente Espírita Bezerra de Menezes - SBEBM"
      />
      <Routes />
    </ConfigProvider>
  );
};

export default hot(App);
