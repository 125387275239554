import { Meta } from "#/components/Meta";
import React, { Fragment } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithFallback,
} from "react-error-boundary";
import { Optional } from "utility-types";
import Modal from "../Modal";

type Props = {
  title?: string;
  onCancel?: () => void;
};

export const Fallback: React.FC<Props> = ({ title, onCancel, children }) => {
  return (
    <Fragment>
      <Meta title={title || "Erro inesperado"} />

      <Modal
        visible={true}
        title={title || "Erro inesperado"}
        onCancel={onCancel || window.location.reload}
        primaryButton={{
          onClick: onCancel,
          children: "Tentar novamente",
        }}
      >
        {children || "Por favor, tente novamente mais tarde."}
      </Modal>
    </Fragment>
  );
};

export const ErrorBoundary: React.FC<
  Optional<ErrorBoundaryPropsWithFallback, "fallback">
> = (props) => {
  return <ReactErrorBoundary fallback={<Fallback />} {...props} />;
};
