import { GlobalContext, GlobalContextFunctions } from "#/api/global.context";
import { PAGE } from "#/constants";
import {
  CreditCardOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Layout, Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LogoImg from "../../assets/logo.jpg";
import styles from "./styles.module.scss";

const { Content, Sider } = Layout;

enum MENU_KEYS {
  REGISTER = "REGISTER",
  MONTHLY = "MONTHLY",
  LOGOUT = "LOGOUT",
}

const MenuWapper: React.FC = ({ children }) => {
  const { state, dispatch } = useContext(GlobalContext);

  if (state.smallScreen) {
    return (
      <Fragment>
        {!state.menuVisible ? (
          <div style={{ position: "fixed", top: "10px", left: 0, zIndex: 1 }}>
            <Button
              icon={<MenuUnfoldOutlined />}
              type="primary"
              onClick={(_) => {
                dispatch({
                  type: GlobalContextFunctions.setMenuVisible,
                  menuVisible: true,
                });
              }}
            ></Button>
          </div>
        ) : null}
        <Drawer
          visible={state.menuVisible}
          placement="left"
          width={280}
          bodyStyle={{
            padding: 0,
            backgroundColor: "#00152a",
          }}
          closeIcon={null}
          onClose={() => {
            dispatch({
              type: GlobalContextFunctions.setMenuVisible,
              menuVisible: false,
            });
          }}
        >
          {children}
        </Drawer>
      </Fragment>
    );
  }

  return (
    <Sider
      width={280}
      style={{
        overflow: "auto",
        minHeight: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      {children}
    </Sider>
  );
};

interface MainLayoutProps {
  children: any;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const isLogged = !!state.session;

  const history = useHistory();

  const [selectedMenu, setSelectedMenu] = useState<string[]>(
    getSelectedKeys(history.location.pathname)
  );

  function getSelectedKeys(pathname: string) {
    function pathnameIn(paths: string[]) {
      return paths.some((i) => i === pathname);
    }

    const selected = [pathname];

    if (pathnameIn([PAGE.REGISTER_FORM(), PAGE.REGISTER_PASSWORD()])) {
      selected.push(MENU_KEYS.REGISTER);
    }

    if (pathnameIn([PAGE.MONTHLY_PAYMENT_HISTORY(), PAGE.PAY_MONTHLY()])) {
      selected.push(MENU_KEYS.MONTHLY);
    }
    return selected;
  }

  useEffect(() => {
    setSelectedMenu(getSelectedKeys(history.location.pathname));
  }, [history.location.pathname]);

  function logout() {
    dispatch({
      type: GlobalContextFunctions.setSession,
      session: undefined,
    });
    history.push(PAGE.LOGIN());
  }

  function handleClick(info: MenuInfo) {
    if (info.key === history.location.pathname) {
      window.location.reload();
    }

    switch (info.key) {
      case PAGE.LOGIN():
      case PAGE.SIGNUP():
      case PAGE.MONTHLY_PAYMENT_HISTORY():
      case PAGE.PAY_MONTHLY():
      case PAGE.REGISTER_FORM():
      case PAGE.REGISTER_PASSWORD():
      case PAGE.SPONTANEOUS_CONTRIBUTION():
        dispatch({ type: GlobalContextFunctions.setResetFeedback });
        dispatch({
          type: GlobalContextFunctions.setMenuVisible,
          menuVisible: false,
        });
        history.push(info.key);
        break;
      case MENU_KEYS.LOGOUT:
        logout();
        break;
      default:
        break;
    }
  }

  return (
    <Layout>
      <MenuWapper>
        <div className={styles["logo"]}>
          <img
            alt="Sociedade Beneficente Espírita Bezerra de Menezes - SBEBM"
            src={LogoImg}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={selectedMenu || []}
          selectedKeys={selectedMenu || []}
          onClick={handleClick}
        >
          {!isLogged ? (
            <>
              <Menu.Item key={PAGE.LOGIN()} icon={<LoginOutlined />}>
                Acessar portal do associado
              </Menu.Item>
              <Menu.Item key={PAGE.SIGNUP()} icon={<UserAddOutlined />}>
                Associe-se
              </Menu.Item>
            </>
          ) : null}
          {isLogged ? (
            <Menu.SubMenu
              key={MENU_KEYS.REGISTER}
              icon={<UserOutlined />}
              title="Cadastro"
            >
              <Menu.Item key={PAGE.REGISTER_FORM()}>Alterar cadastro</Menu.Item>
              <Menu.Item key={PAGE.REGISTER_PASSWORD()}>
                Alterar senha
              </Menu.Item>
            </Menu.SubMenu>
          ) : null}
          {isLogged ? (
            <Menu.SubMenu
              key={MENU_KEYS.MONTHLY}
              icon={<CreditCardOutlined />}
              title="Mensalidades"
            >
              <Menu.Item key={PAGE.MONTHLY_PAYMENT_HISTORY()}>
                Histórico
              </Menu.Item>
              <Menu.Item key={PAGE.PAY_MONTHLY()}>Pagar mensalidade</Menu.Item>
            </Menu.SubMenu>
          ) : null}

          <Menu.Item
            key={PAGE.SPONTANEOUS_CONTRIBUTION()}
            icon={<CreditCardOutlined />}
          >
            Contribuição espontânea
          </Menu.Item>

          {isLogged ? (
            <Menu.Item key={MENU_KEYS.LOGOUT} icon={<LogoutOutlined />}>
              Sair
            </Menu.Item>
          ) : null}
        </Menu>
      </MenuWapper>
      <Layout
        className="site-layout"
        style={{ marginLeft: state.smallScreen ? 0 : 280 }}
      >
        <Content
          style={{
            padding: state.smallScreen ? "36px 8px 8px" : "24px 16px",
            overflow: "initial",
            minHeight: "100vh",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
