import { APPLICATION, STORAGE } from "#/constants";
import ky from "ky-universal";

export const externalRequest = ky.extend({
  timeout: 5 * 60 * 1000,
});

export const request = ky.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        try {
          const session = JSON.parse(
            sessionStorage.getItem(STORAGE.TOKEN_KEY) || "{}"
          );
          if (session?.token) {
            request.headers.set("Authorization", `Bearer ${session.token}`);
          }
        } catch (error) {
          console.log("error", error);
        }

        return request;
      },
    ],
    afterResponse: [
      (request, __, response) => {
        if (response.status === 401 || response.status === 403) {
          sessionStorage.removeItem(STORAGE.TOKEN_KEY);
          if (request.url !== `${APPLICATION.BFF_URL}/v1/login`) {
            window.location.reload();
          }
        }

        return response;
      },
    ],
  },
  timeout: 5 * 60 * 1000,
  prefixUrl: APPLICATION.BFF_URL,
});
