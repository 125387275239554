import { SMALL_SCREEN, STORAGE } from "#/constants";
import React, { createContext, Dispatch, useReducer } from "react";
import { UserService, UserSession } from "./user.service";

interface GlobalState {
  session?: UserSession;
  smallScreen?: boolean;
  menuVisible?: boolean;
  resetFeedback?: number;
}

const initialState: GlobalState = {
  session: UserService.getSession(),
  smallScreen: window.innerWidth < SMALL_SCREEN,
  menuVisible: false,
  resetFeedback: 0,
};

export enum GlobalContextFunctions {
  setSession,
  setSmallScreen,
  setMenuVisible,
  setResetFeedback,
}

interface IContextProps {
  state: GlobalState;
  dispatch: Dispatch<IAction>;
}

const GlobalContext = createContext({} as IContextProps);

interface IAction extends Partial<GlobalState> {
  type: GlobalContextFunctions;
}

const reducer = (state = initialState, action: IAction): GlobalState => {
  switch (action.type) {
    case GlobalContextFunctions.setSession:
      sessionStorage.removeItem(STORAGE.TOKEN_KEY);
      if (action.session) {
        sessionStorage.setItem(
          STORAGE.TOKEN_KEY,
          JSON.stringify(action.session)
        );
      }
      return { ...state, session: action.session };
    case GlobalContextFunctions.setSmallScreen:
      return { ...state, smallScreen: action.smallScreen };
    case GlobalContextFunctions.setMenuVisible:
      return { ...state, menuVisible: action.menuVisible };
    case GlobalContextFunctions.setResetFeedback:
      return { ...state, resetFeedback: new Date().getTime() };
    default:
      return { ...state };
  }
};

const GlobalContextProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch } as IContextProps;
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

const GlobalContextConsumer = GlobalContext.Consumer;
export { GlobalContext, GlobalContextProvider, GlobalContextConsumer };
