import { GlobalContext } from "#/api/global.context";
import { STORAGE } from "#/constants";
import { request } from "#/utils/request";
import { useContext } from "react";

export interface UserSession {
  token: string;
}

enum USER_STATUS {
  PASSWORD,
  CPF,
  RG,
  COMPLETED,
}

interface UserStatus {
  status: USER_STATUS[];
}

interface LoginResponse {
  token: string;
}

export class UserService {
  static async updateCustomer(data: any) {
    try {
      return await request
        .post(`v1/public/customer`, {
          json: data,
        })
        .json();
    } catch (error) {
      throw await error.response.json();
    }
  }

  static async checkStatus(
    username: string,
    recaptchaToken: string
  ): Promise<UserStatus> {
    try {
      return await request
        .get(`v1/public/customer/${username}/status`, {
          headers: { Authorization: recaptchaToken },
        })
        .json();
    } catch (error) {
      throw await error.response.json();
    }
  }

  static async checkDocument(
    username: string,
    documentType: string,
    documentNumber: string
  ): Promise<string[]> {
    try {
      return await request
        .get(
          `v1/public/customer/${username}/status/${documentType.toLocaleLowerCase()}/${documentNumber.replace(
            /\D/g,
            ""
          )}`
        )
        .json();
    } catch (error) {
      throw await error.response.json();
    }
  }

  static async login(
    username: string,
    password: string
  ): Promise<LoginResponse> {
    try {
      return await request
        .post(`v1/login`, {
          json: {
            password,
            username,
          },
        })
        .json();
    } catch (error) {
      throw await error.response.json();
    }
  }

  static getSession(): UserSession | undefined {
    try {
      const data = sessionStorage.getItem(STORAGE.TOKEN_KEY);
      return JSON.parse(data || "");
    } catch (error) {
      return;
    }
  }

  static isLogged() {
    const { state } = useContext(GlobalContext);
    return !!state.session;
  }
}
