import { Button, Modal as ModalAntd } from "antd";
import { ButtonProps } from "antd/lib/button";
import { ModalProps } from "antd/lib/modal";
import React from "react";

export interface Props extends ModalProps {
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
}

const Modal: React.FunctionComponent<Props> = ({
  children,
  primaryButton,
  secondaryButton,
  ...props
}) => {
  const footer: React.ReactNode[] = [];

  if (secondaryButton) {
    footer.push(<Button shape="round" {...secondaryButton} />);
  }

  if (primaryButton) {
    footer.push(<Button type="primary" shape="round" {...primaryButton} />);
  }

  return (
    <ModalAntd {...props} footer={footer}>
      {children}
    </ModalAntd>
  );
};

export default Modal;
