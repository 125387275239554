import { PAGSEGURO_ENV } from "./components/DirectPayment/model";

export const APPLICATION = {
  BFF_URL: process.env.REACT_APP_BFF_URL || `http://localhost:8080/api`,
  PUBLIC_URL: process.env.PUBLIC_URL || "/",
  IS_TEST: process.env.NODE_ENV === "test",
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY || "",
  PAGSEGURO_ENVIRINMENT: (process.env.REACT_APP_PAGSEGURO_MODE ||
    "sandbox") as PAGSEGURO_ENV,
  TERMS_URL: process.env.REACT_APP_TERMS_URL || "",
};

export const STORAGE = {
  TOKEN_KEY: "auth-token",
};

export const SMALL_SCREEN = 700;

export const PAYMENT = {
  MIN_AMOUNT_PER_ITEM: 5,
  MAX_AMOUNT: 9999999,
};

export const PAGE = {
  LOGIN: () => "/login",
  SIGNUP: () => "/associe-se",
  SIGNUP_CONFIRMATION: () => "/confirmar/:code",
  MONTHLY_PAYMENT_HISTORY: () => "/mensalidades",
  PAY_MONTHLY: () => "/mensalidades/pagar",
  REGISTER_FORM: () => "/cadastro",
  REGISTER_PASSWORD: () => "/cadastro/senha",
  SPONTANEOUS_CONTRIBUTION: () => "/contribuicao-espontanea",
};
